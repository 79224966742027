export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

// export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
// export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
// export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

// export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
// export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
// export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const UPDATE_PASSWORD_BEGIN = "UPDATE_PASSWORD_BEGIN";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const GET_CURRENT_USER_BEGIN = "GET_CURRENT_USER_BEGIN";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";

export const GET_LOCATIONS_BEGIN = "GET_LOCATIONS_BEGIN";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const SLIDING_PANEL = "SLIDING_PANEL";

export const CREATE_LOCATION_BEGIN = "CREATE_LOCATION_BEGIN";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_ERROR = "CREATE_LOCATION_ERROR";

export const DELETE_LOCATION_BEGIN = "DELETE_LOCATION_BEGIN";
export const DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR";

export const EDIT_LOCATION_BEGIN = "EDIT_LOCATION_BEGIN";
export const EDIT_LOCATION_SUCCESS = "EDIT_LOCATION_SUCCESS";
export const EDIT_LOCATION_ERROR = "EDIT_LOCATION_ERROR";

export const GET_MY_DRIVERS_BEGIN = "GET_MY_DRIVERS_BEGIN";
export const GET_MY_DRIVERS_SUCCESS = "GET_MY_DRIVERS_SUCCESS";

export const CREATE_MY_DRIVERS_BEGIN = "CREATE_MY_DRIVERS_BEGIN";
export const CREATE_MY_DRIVERS_SUCCESS = "CREATE_MY_DRIVERS_SUCCESS";
export const CREATE_MY_DRIVERS_ERROR = "CREATE_MY_DRIVERS_ERROR";

export const EDIT_MY_DRIVERS_BEGIN = "EDIT_MY_DRIVERS_BEGIN";
export const EDIT_MY_DRIVERS_SUCCESS = "EDIT_MY_DRIVERS_SUCCESS";
export const EDIT_MY_DRIVERS_ERROR = "EDIT_MY_DRIVERS_ERROR";

export const GET_CONSTRUCTORS_BEGIN = "GET_CONSTRUCTORS_BEGIN";
export const GET_CONSTRUCTORS_SUCCESS = "GET_CONSTRUCTORS_SUCCESS";

export const HANDLE_CHANGE = "HANDLE_CHANGE";

export const CREATE_CONSTRUCTOR_BEGIN = "CREATE_CONSTRUCTOR_BEGIN";
export const CREATE_CONSTRUCTOR_SUCCESS = "CREATE_CONSTRUCTOR_SUCCESS";
export const CREATE_CONSTRUCTOR_ERROR = "CREATE_CONSTRUCTOR_ERROR";

export const DELETE_CONSTRUCTOR_BEGIN = "DELETE_CONSTRUCTOR_BEGIN";
export const DELETE_CONSTRUCTOR_ERROR = "DELETE_CONSTRUCTOR_ERROR";

export const EDIT_CONSTRUCTOR_BEGIN = "EDIT_CONSTRUCTOR_BEGIN";
export const EDIT_CONSTRUCTOR_SUCCESS = "EDIT_CONSTRUCTOR_SUCCESS";
export const EDIT_CONSTRUCTOR_ERROR = "EDIT_CONSTRUCTOR_ERROR";

export const GET_DRIVERS_BEGIN = "GET_DRIVERS_BEGIN";
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
export const GET_DRIVERS_PER_PAGE_BEGIN = "GET_DRIVERS_PER_PAGE_BEGIN";
export const GET_DRIVERS_PER_PAGE_SUCCESS = "GET_DRIVERS_PER_PAGE_SUCCESS";

export const CREATE_DRIVER_BEGIN = "CREATE_DRIVER_BEGIN";
export const CREATE_DRIVER_SUCCESS = "CREATE_DRIVER_SUCCESS";
export const CREATE_DRIVER_ERROR = "CREATE_DRIVER_ERROR";

export const EDIT_DRIVER_BEGIN = "EDIT_DRIVER_BEGIN";
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS";
export const EDIT_DRIVER_ERROR = "EDIT_DRIVER_ERROR";

export const DELETE_DRIVER_BEGIN = "DELETE_DRIVER_BEGIN";
export const DELETE_DRIVER_ERROR = "DELETE_DRIVER_ERROR";

export const GET_EXCEL_BEGIN = "GET_EXCEL_BEGIN";
export const GET_EXCEL_SUCCESS = "GET_EXCEL_SUCCESS";

export const GET_TEAM_STANDINGS_BEGIN = "GET_TEAM_STANDINGS_BEGIN";
export const GET_TEAM_STANDINGS_SUCCESS = "GET_TEAM_STANDINGS_SUCCESS";

export const CREATE_TEAM_STANDINGS_BEGIN = "CREATE_TEAM_STANDINGS_BEGIN";
export const CREATE_TEAM_STANDINGS_SUCCESS = "CREATE_TEAM_STANDINGS_SUCCESS";
export const CREATE_TEAM_STANDINGS_ERROR = "CREATE_TEAM_STANDINGS_ERROR";

export const EDIT_TEAM_STANDINGS_BEGIN = "EDIT_TEAM_STANDINGS_BEGIN";
export const EDIT_TEAM_STANDINGS_SUCCESS = "EDIT_TEAM_STANDINGS_SUCCESS";
export const EDIT_TEAM_STANDINGS_ERROR = "EDIT_TEAM_STANDINGS_ERROR";

export const GET_TEAM_STANDINGS_BY_ID_BEGIN = "GET_TEAM_STANDINGS_BY_ID_BEGIN";
export const GET_TEAM_STANDINGS_BY_ID_SUCCESS =
  "GET_TEAM_STANDINGS_BY_ID_SUCCESS";
