import logo from "../assets/images/logo.svg";

const Logo = () => {
  return (
    <>
      <img src={logo} alt="race-car" className="logo" />
    </>
  );
};

export default Logo;
